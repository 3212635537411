import './Background.scss';
import * as React from "react";

class Background extends React.Component {

    theme() {
        return this.props.rgb ? "RGB" : "";
    }

    render() {
        return (
            <div className="FenrisBackground">
                <div className={this.theme() + " FenrisBackgroundPattern"}  />
                <div className="FenrisBackgroundFilter" />
            </div>
        );
    }
}

export default Background;
