import './App.css';
import * as React from "react";
import Background from "./components/Background/Background";
import FenrisText from "./components/FenrisText/FenrisText";
import SocialLinks from "./components/SocialLinks/SocialLinks";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {rgb: false};
    }

    onLogoClick = e =>{
        this.setState({
            rgb: !this.state.rgb
        });
    }

    render() {
        return (
            <div className="App">
                <Background rgb={this.state.rgb} />
                <div className="FenrisMain">
                    <div className="FenrisContent">
                        <div className="FenrisTextContainer">
                            <FenrisText onLogoClick={this.onLogoClick} />
                        </div>
                        <div className="SocialLinksContainer">
                            <SocialLinks />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default App;
