import './SocialLinks.scss';
import * as React from "react";
import SoundcloudSVG from "./SoundCloudLogo.svg";
import YoutubeSVG from "./YoutubeLogo.svg";
import InstagramSVG from "./InstagramLogo.svg";
import InstagramColorSVG from "./InstagramLogoColor.svg";
import ContactSVG from "./ContactLogo.svg";

class SocialLinks extends React.Component {
    render() {
        return (
            <div className="SocialLinks">
                <a target="_top" href="https://soundcloud.com/user-977162336" className="SocialLinkImageContainer Soundcloud">
                    <img className="SocialLinkImage"
                         id="Soundcloud" src={SoundcloudSVG}
                         alt="Link to Fenris Soundcloud"/>
                </a>
                <a className="break-all" />
                <a target="_top" href="https://www.youtube.com/channel/UCu9WqBw5uHs1eG9PivED3gg/" className="SocialLinkImageContainer Youtube">
                    <img className="SocialLinkImage"
                         id="Youtube" src={YoutubeSVG}
                         alt="Link to Fenris Youtube"
                    />
                </a>
                <a className="break break-all" />
                <a target="_top" href="https://www.instagram.com/official.fenris/" className="SocialLinkImageContainer Instagram">
                    <img className="SocialLinkImage"
                         id="Instagram" src={InstagramSVG}
                         alt="Link to Fenris Instagram"
                         onMouseOver={e => (e.currentTarget.src = InstagramColorSVG)}
                         onMouseOut={e => (e.currentTarget.src = InstagramSVG)}/>
                </a>
                <a target="_top" href="https://www.instagram.com/official.fenris/" className="SocialLinkImageContainer InstagramHidden">
                    <img className="SocialLinkImage"
                         id="Instagram"
                         src={InstagramColorSVG}
                         alt="Link to Fenris Instagram" />
                </a>
                <a className="break-all" />
                <a target="_top" href="mailto:fenris.official@outlook.de" className="SocialLinkImageContainer Contact">
                    <img className="SocialLinkImage"
                         id="Contact"
                         src={ContactSVG}
                         alt="Link to contact Fenris"/>
                </a>
            </div>
        );
    }
}

export default SocialLinks;
