import './FenrisText.scss';
import * as React from "react";
import FenrisTextSVG from "./FenrisTextBlack.svg";

class FenrisText extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="FenrisText">
                <img onClick={this.props.onLogoClick} id="FenrisText" src={FenrisTextSVG} alt="Fenris Logo"/>
            </div>
        );
    }
}

export default FenrisText;
